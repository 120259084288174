import React from "react"
import styled from "styled-components"
import { Field, ErrorMessage, getIn } from "formik"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import OpenEye from "../../zzz/atoms/icons/files/open-eye.jsx"
import ClosedEye from "../../zzz/atoms/icons/files/closed-eye.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Input = ({
  label,
  type,
  name,
  autoComplete,
  placeholder,
  toggle,
  light = false,
  errors = {},
  touched = {},
  hidePassword,
  id,
  handleChange = () => {},
}) => {
  return (
    <Container light={light} checkbox={type === "checkbox"}>
      <label htmlFor={id}>{label}</label>
      <Field
        id={id}
        type={type}
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        style={getStyles(errors, name)}
        onInput={handleChange}
        onBlur={handleChange}
      />
      {toggle && (
        <SvgIcon
          SvgComponent={hidePassword ? OpenEye : ClosedEye}
          onClick={toggle}
          hue={light ? "midnight" : "white"}
          shade={light ? "000" : "000"}
        />
      )}
      <ErrorMessage
        name={name}
        render={(msg) => <div className="error">{msg}</div>}
        className="error"
      />
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  ${({ checkbox }) => {
    if (checkbox) {
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "10px 0",
        input: {
          margin: "10px",
        },
      }
    } else {
      return {
        flexDirection: "column",
      }
    }
  }};
  display: flex;
  position: relative;
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }

  div.error {
    margin: 10px 0;
    color: ${({ light }) => (light ? "black" : "#fd3237")};
  }

  span.icon {
    position: absolute;
    top: 57px;
    right: 5px;
    cursor: pointer;
  }

  label {
    color: ${({ light }) => (light ? "#223247" : "#f0f1f3")};
    font-family: Roboto;
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #fd3237;
      text-align: right;
    }
  }

  input {
    border: 1px solid ${({ light }) => (light ? "#CFD5DB" : "#3e4b5c")};
    // input font size must be at least 16px otherwise it causes zoom-in on mobile
    font-size: 16px;
    border-radius: 3px;
    background-color: ${({ light }) => (light ? "#FFFFFF" : "#223247")};
    /* background-color: #223247; */
    padding: 15px;
    color: ${({ light }) => (light ? "color: #223247" : "white")};
  }
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      border: "1px solid red",
    }
  }
}

export default Input
