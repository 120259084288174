import React from "react"

const IconClosedEye = ({ fill }) => (
  <svg width="18" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.67 14.02c.04.03.08.11.08.17 0 .04-.02.1-.05.14l-.27.34a.25.25 0 01-.17.08.25.25 0 01-.14-.05L.33 1.48a.25.25 0 01-.08-.17c0-.04.02-.1.05-.14L.57.83A.25.25 0 01.74.75c.04 0 .1.02.14.05l16.79 13.22zM9 3.38c-.89 0-1.69.34-2.3.88l-1.26-.98C6.38 2.85 7.97 2.5 9 2.5c3.36 0 6.3 1.96 7.78 4.85.05.1.1.28.1.4s-.05.3-.1.4a9.1 9.1 0 01-1.94 2.53l-.7-.55A9.79 9.79 0 0016 7.75a9.43 9.43 0 00-3.55-3.54 4.33 4.33 0 01.32 4.84l-.7-.55c.27-.49.43-1.03.43-1.62A3.5 3.5 0 009 3.38zm1.66 4.01L8.92 6.01c.04-.12.08-.32.08-.45 0-.12-.03-.31-.08-.43h.1a1.73 1.73 0 011.64 2.26zM4.71 6.05l.81.63-.02.2a3.5 3.5 0 003.5 3.5c.35 0 .68-.07 1-.17l.81.64a4.36 4.36 0 01-6.19-3.96v-.01c0-.24.05-.6.1-.83zM9 12.13c.96 0 1.89-.2 2.76-.54l.8.63c-.94.43-2.53.78-3.56.78a8.77 8.77 0 01-7.78-4.85c-.05-.1-.1-.28-.1-.4s.05-.3.1-.4a9.1 9.1 0 011.94-2.53l.7.56A8.07 8.07 0 002 7.75a7.88 7.88 0 007 4.38z"
      fill={fill}
    />
  </svg>
)

export default IconClosedEye
