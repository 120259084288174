import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useQueryParam, NumberParam } from "use-query-params"
import { unescapeHtml } from "../../lib/stringHandling"
import queryString from "query-string"

const SEO = ({
  description,
  title,
  overwriteYoastTitle = false,
  overwriteYoastMetaDescription = false,
  location,
  post_id,
  noindex = false,
  seo = {},
}) => {
  const [pageNum, setPageNum] = useQueryParam("page", NumberParam)
  const homePageSeoQueryData = useStaticQuery(HOME_PAGE_SEO_QUERY)
  let fallbackSeoDescription =
    homePageSeoQueryData.localWpGraphQlPages.seo.yoastDescription

  let brandSpecificSchema = ""

  if (
    location.pathname === "/all-brands/restonic-beds/" ||
    location.pathname === "/all-brands/restonic-beds"
  ) {
    brandSpecificSchema = `{"@context":"http://schema.org","@type":"ItemList","itemListElement":[{"@type":"ListItem","position":1,"url":"https://www.themattresswarehouse.co.za/product/restonic-recover-bed/"},{"@type":"ListItem","position":2,"url":"https://www.themattresswarehouse.co.za/product/restonic-idream-zonal-flex-plush-bed/"},{"@type":"ListItem","position":3,"url":"https://www.themattresswarehouse.co.za/product/restonic-idream-dream-whisper-ultra-plush-bed/"}]}`
  } else if (
    location.pathname === "/all-brands/edblo-beds/" ||
    location.pathname === "/all-brands/edblo-beds"
  ) {
    brandSpecificSchema = `{"@context":"http://schema.org","@type":"ItemList","itemListElement":[{"@type":"ListItem","position":1,"url":"https://www.themattresswarehouse.co.za/product/edblo-gobabis-bed/"},{"@type":"ListItem","position":2,"url":"https://www.themattresswarehouse.co.za/product/edblo-rundu-bed/"},{"@type":"ListItem","position":3,"url":"https://www.themattresswarehouse.co.za/product/edblo-ruacana-bed/"}]}`
  } else if (
    location.pathname === "/all-brands/sealy-beds/" ||
    location.pathname === "/all-brands/sealy-beds"
  ) {
    brandSpecificSchema = `{"@context":"http://schema.org","@type":"ItemList","itemListElement":[{"@type":"ListItem","position":1,"url":"https://www.themattresswarehouse.co.za/product/sealy-posturepedic-ponto-firm-bed/"},{"@type":"ListItem","position":2,"url":"https://www.themattresswarehouse.co.za/product/sealy-posturepedic-belgro-medium-bed/"},{"@type":"ListItem","position":3,"url":"https://www.themattresswarehouse.co.za/product/sealy-crown-jewel-lindsay-plush-bed/"}]}`
  }

  if (typeof seo == "undefined") {
    console.error(
      "SEO: Invalid SEO setup for post_id = " +
        post_id +
        "  |  location = " +
        JSON.stringify(location) +
        "  |  seo = " +
        JSON.stringify(seo)
    )
    seo = {}
  }

  // Prepare title
  if (seo.yoastTitle && !overwriteYoastTitle) {
    title = unescapeHtml(seo.yoastTitle)
  } else {
    title = `${unescapeHtml(title)} | The Mattress Warehouse`
  }
  if ('"' == title[0]) {
    console.log("Fixing unexpected quote in title: " + title)
    title = title.replace(/(?:^"|"$)/g, "")
  }
  // Add page number in title
  if (title && pageNum && pageNum > 1) {
    const splitTitle = title.split("|")
    if (splitTitle && splitTitle.length > 1) {
      title = [
        `${splitTitle[0]} Page ${pageNum} `,
        ...splitTitle.slice(1),
      ].join("|")
    }
  }

  // Prepare description
  if (!overwriteYoastMetaDescription && seo.yoastDescription) {
    description = seo.yoastDescription
  }
  // Add page number to description and fallback description
  if (description && pageNum && pageNum > 1) {
    description = `Browse Page ${pageNum} - ${description}`
  }
  if (fallbackSeoDescription && pageNum && pageNum > 1) {
    fallbackSeoDescription = `Browse page ${pageNum} - ${fallbackSeoDescription}`
  }

  let seo_error_printed = false
  if (!seo || typeof seo == "undefined" || seo === {}) {
    console.error(
      "SEO: seo object invalid for post_id = " +
        post_id +
        "  |  location = " +
        JSON.stringify(location) +
        "  |  seo = " +
        JSON.stringify(seo)
    )
    seo_error_printed = true
  }

  if (!("yoastMeta" in seo)) {
    if (!seo_error_printed) {
      console.error(
        "SEO: No Yoast Meta found for post_id = " +
          post_id +
          "  |  location = " +
          JSON.stringify(location) +
          "  |  seo = " +
          JSON.stringify(seo.yoastMeta)
      )
      seo_error_printed = true
    }
    seo["yoastMeta"] = `[
      {"property":"og:title","content":"${title}"},
      {"property":"og:site_name","content":"The Mattress Warehouse"},
      {"property":"og:description", "content": "${description}"}
      ]`
  }

  if (!("yoastSchema" in seo)) {
    if (!seo_error_printed) {
      console.error(
        "SEO: No Yoast Schema found for post_id = " +
          post_id +
          "  |  location = " +
          JSON.stringify(location) +
          "  |  seo = " +
          JSON.stringify(seo.yoastSchema)
      )
      seo_error_printed = true
    }
    seo["yoastSchema"] = ""
  }
  if (!("woocommerceSchema" in seo)) {
    if (!seo_error_printed) {
      console.error(
        "SEO: No WooCommerce Meta found for post_id = " +
          post_id +
          "  |  location = " +
          JSON.stringify(location) +
          "  |  seo = " +
          JSON.stringify(seo.woocommerceSchema)
      )
      seo_error_printed = true
    }
    seo["woocommerceSchema"] = ""
  }

  let yoastMetaParsed = undefined
  if (seo && seo.yoastMeta) {
    try {
      yoastMetaParsed = []
      yoastMetaParsed = JSON.parse(seo.yoastMeta)
      let is_description_set = false

      if (Array.isArray(yoastMetaParsed)) {
        for (let i = 0; i < yoastMetaParsed.length; ++i) {
          if (yoastMetaParsed[i].property) {
            if (yoastMetaParsed[i].property == "og:title") {
              yoastMetaParsed[i].content = title
            } else if (yoastMetaParsed[i].property == "og:description") {
              if (overwriteYoastMetaDescription) {
                yoastMetaParsed[i].content = description
              }
              if (!yoastMetaParsed[i].content) {
                yoastMetaParsed[i].content = fallbackSeoDescription
              }
            }
          } else if (yoastMetaParsed[i].name) {
            if (yoastMetaParsed[i].name == "twitter:title") {
              yoastMetaParsed[i].content = title
            } else if (yoastMetaParsed[i].name == "twitter:description") {
              if (overwriteYoastMetaDescription) {
                yoastMetaParsed[i].content = description
              }
              if (!yoastMetaParsed[i].content) {
                yoastMetaParsed[i].content = fallbackSeoDescription
              }
            } else if (yoastMetaParsed[i].name == "description") {
              is_description_set = true
              if (overwriteYoastMetaDescription) {
                yoastMetaParsed[i].content = description
              }
              if (!yoastMetaParsed[i].content) {
                yoastMetaParsed[i].content = fallbackSeoDescription
              }
            }
          }
        }
      }
      if (!is_description_set) {
        yoastMetaParsed.push({
          name: "description",
          content: `${description ? description : fallbackSeoDescription}`,
        })
      }
      // Add google site verification
      yoastMetaParsed.push({
        name: "google-site-verification",
        content: "8ztGvt7o7qn_F8nUPr2U4_ws_ILpJvkTLqFMROg5_lI",
      })
      // eslint-disable-next-line no-empty
    } catch {}
  }

  // Prepare canonical URL
  let canonicalTagUrl = `${process.env.GATSBY_FRONTEND_URL}${location.pathname}`
  if (!canonicalTagUrl.endsWith("/")) {
    canonicalTagUrl = `${canonicalTagUrl}/`
  }
  // Add page number to canonical URL (page=1 should still reference the original URL)
  if (canonicalTagUrl && pageNum && pageNum > 1) {
    const parsedUrl = queryString.parseUrl(canonicalTagUrl)
    parsedUrl.query.page = pageNum
    canonicalTagUrl = queryString.stringifyUrl(parsedUrl)
  }

  // This replaces the paged "/sleep-matters/page-20/" urls canonical url to "/sleep-matters/"
  if (canonicalTagUrl.indexOf("sleep-matters/page-") >= 0) {
    canonicalTagUrl = `${process.env.GATSBY_FRONTEND_URL}/sleep-matters/`
  }

  // function generate_page_breadcrumb_list_Schema(location) {
  //   /** Calculate BreadcrumbList Schema - START */
  //   let breadcrumbListSchema = ``
  //   // Get URI
  //   let schema_uri = location.pathname;
  //   // Clear start and end forward-slash
  //   if (schema_uri.startsWith("/")) {
  //     schema_uri = schema_uri.slice(1);
  //   }
  //   if (schema_uri.endsWith("/")) {
  //     schema_uri = schema_uri.slice(0, -1);
  //   }
  //   // Now that schema_uri is clean we can start to generate the schema
  //   let schema_uri_arr = schema_uri.split("/")
  //   let breadcrumbListSchemaItems = ``
  //   schema_uri_arr.forEach((e, i) => {
  //     const position = i + 1
  //     const title = e.toLowerCase().split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  //     let url = ''
  //     if (i > 0) {
  //       url = "https://www.themattresswarehouse.co.za/"
  //       for (let k = 0; k <= i; k++) {
  //         url += schema_uri_arr[k] + "/"
  //       }
  //     } else {
  //       url = "https://www.themattresswarehouse.co.za/" + e + "/"
  //     }
  //     if (breadcrumbListSchemaItems !== ``) {
  //       breadcrumbListSchemaItems += `,`
  //     }
  //     breadcrumbListSchemaItems += `{
  //       "@type": "ListItem",
  //       "position": ${position},
  //       "name": "${title}",
  //       "item": "${url}"
  //     }`
  //   })
  //   breadcrumbListSchema = `
  //   {
  //     "@context": "https://schema.org/",
  //     "@type": "BreadcrumbList",
  //     "itemListElement": [${breadcrumbListSchemaItems}]
  //   }
  //   `
  //   /** Calculate BreadcrumbList Schema - END */
  //   return breadcrumbListSchema
  // }
  // const breadcrumbListSchema = generate_page_breadcrumb_list_Schema(location);

  return (
    <Helmet
      htmlAttributes={{ lang: "en-ZA" }}
      key={location}
      title={title}
      titleTemplate={`%s`}
      meta={yoastMetaParsed}
    >
      <link rel="canonical" href={canonicalTagUrl} />
      {!seo.yoastSchema ||
      (typeof seo.yoastSchema == "string" &&
        seo.yoastSchema.length < 5) ? null : (
        <script type="application/ld+json" className="Yoast-Schema">
          {seo.yoastSchema}
        </script>
      )}
      {!seo.woocommerceSchema ||
      (typeof seo.woocommerceSchema == "string" &&
        seo.woocommerceSchema.length < 5) ? null : (
        <script type="application/ld+json" className="WooCommerce-Schema">
          {seo.woocommerceSchema}
        </script>
      )}
      {!seo.branchSchema ||
      (typeof seo.branchSchema == "string" &&
        seo.branchSchema.length < 5) ? null : (
        <script type="application/ld+json" className="Branch-Schema">
          {seo.branchSchema}
        </script>
      )}

      {brandSpecificSchema !== "" && (
        <script type="application/ld+json" className="Brand-Schema">
          {brandSpecificSchema}
        </script>
      )}

      {/*{breadcrumbListSchema !== "" && (*/}
      {/*  <script type={"application/ld+json"} className={"Breadcrumb-List-Schema"}>*/}
      {/*    {breadcrumbListSchema}*/}
      {/*  </script>*/}
      {/*)}*/}

      {noindex && <meta name="robots" content="noindex, nofollow" />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

// ===============
//      QUERY
// ===============
const HOME_PAGE_SEO_QUERY = graphql`
  {
    localWpGraphQlPages(slug: { eq: "home" }) {
      seo {
        yoastDescription
      }
    }
  }
`
