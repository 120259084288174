import React from "react"

const IconOpenEye = ({ fill }) => (
  <svg width="16" height="11" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 6.63c-.97 0-1.75-.79-1.75-1.75V4.8a1.3 1.3 0 001.66-1.66l.09-.01a1.75 1.75 0 010 3.5zm7.78-1.28c.05.1.1.28.1.4s-.05.3-.1.4A8.77 8.77 0 018 11 8.77 8.77 0 01.22 6.15c-.05-.1-.1-.28-.1-.4s.05-.3.1-.4A8.77 8.77 0 018 .5c3.36 0 6.3 1.96 7.78 4.85zM8 1.38a3.5 3.5 0 10.01 7 3.5 3.5 0 00-.01-7zm0 8.75a7.88 7.88 0 007-4.38 9.42 9.42 0 00-3.55-3.54 4.38 4.38 0 11-6.9 0A9.42 9.42 0 001 5.75a7.88 7.88 0 007 4.38z"
      fill={fill}
    />
  </svg>
)

export default IconOpenEye
