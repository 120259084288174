import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import SplitLayout from "./SplitLayout"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { Formik, Form } from "formik"
import Input from "./Input"
import Error from "./Error"
import AuthContext from "../../context/AuthContext"
import CartContext from "../../context/CartContext"
import BlurLoader from "../../zzz/organisms/blurLoader"
import Logo from "../../zzz/atoms/logo"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import ShowOnButton from "./ShowOnButton"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { getImage } from "../../lib/util"

// After logging in, never redirect back to these pages
const redirectBlacklist = ["/autres/", "/app/"]

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Login = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const { performLogin, loggedInState } = useContext(AuthContext)
  const { ensureCurrentCart } = useContext(CartContext)
  const { file } = useStaticQuery(IMAGE)

  const submitHandler = ({ email, password }) => {
    performLogin(email, password, setLoading, setApiError, ensureCurrentCart)
  }

  // Function that checks if user is logged in and redirects if true
  const loginCheck = () => {
    if (loggedInState === true) {
      console.log("Logged in")

      // We check if a fromUrl was passed from wherever the user clicked on "Log in"
      // But if the fromUrl contains one of the redirectBlacklist URL's, we don't use it
      if (
        location.state &&
        location.state.fromUrl &&
        !redirectBlacklist.some((badurl) =>
          location.state.fromUrl.includes(badurl)
        )
      ) {
        navigate(`${location.state.fromUrl}`, {
          replace: true,
          state: { showNotice: "login" },
        })
      } else
        navigate(`/my-account/`, {
          replace: true,
          state: { showNotice: "login" },
        })
    }
  }

  // Just in case, but probably not necessary
  useEffect(() => {
    loginCheck()
  }, [loggedInState])

  return (
    <SplitLayout image={getImage(file)}>
      <Skinny>
        <BlurLoader
          loading={loading || loggedInState === null}
          message="Logging in"
          shade="light"
        >
          <Link to="/">
            <Logo />
          </Link>
          <Title>Login</Title>
          {apiError && <Error error={apiError.content} />}
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={validate}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, errors, touched }) => (
              <>
                <Form>
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="jane@doe.com"
                    errors={errors}
                    touched={touched}
                  />
                  <Input
                    label="Password:"
                    type={hidePassword ? "password" : "text"}
                    hidePassword={hidePassword}
                    name="password"
                    autoComplete="new-password"
                    errors={errors}
                    touched={touched}
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                  />

                  <Button type="submit" disabled={loading}>
                    <div>LOGIN</div>
                    <SvgIcon
                      SvgComponent={ArrowRight}
                      size="lg"
                      hue="white"
                      shade="000"
                    />
                  </Button>
                </Form>
                <Extra>
                  New here? <Link to="/register/">Register</Link>
                </Extra>
                <Extra style={{ margin: "20px 0" }}>
                  <Link to="/reset/">Forgot Password?</Link>
                </Extra>
              </>
            )}
          </Formik>
          <ShowOnButton />
        </BlurLoader>
      </Skinny>
    </SplitLayout>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Extra = styled.div`
  color: white;
  text-align: center;
  a {
    color: white;
    text-decoration: underline;
  }
`

const Button = styled.button`
  background: #fd3237;
  border-radius: 3px;
  font-size: 20px;
  color: white;
  border: none;
  display: flex;
  padding: 0;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    width: 100%;
  }
  span.icon {
    background: #ed1c24;
    padding: 7px;
  }
`
const Title = styled.h3`
  font-size: 39px;
  color: white;
  text-align: center;
  margin: 20px 0;
`

const Skinny = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .show-on {
    margin-top: 20px;
  }
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const validate = ({ email, password }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")
  addIf(!password, "password", "Password is required")
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

const IMAGE = graphql`
  {
    file(relativePath: { eq: "excited-couple.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default Login
