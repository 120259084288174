import React from "react"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Login from "../components/Auth/Login"

const LoginPage = ({ location }) => {
  const post_id = -1 //-1 means no post id
  const seo = {
    yoastTitle: "Login",
    yoastDescription:
      "Log in to your account at The Mattress Warehouse to track your order, update your details, and more.",
    yoastMeta: `[
      {"property":"og:title","content":"Login"},
      {"property":"og:site_name","content":"The Mattress Warehouse"}
    ]`,
    yoastSchema: "",
    woocommerceSchema: "",
  }

  return (
    <Layout location={location} blank>
      <SEO title="Login" location={location} post_id={post_id} seo={seo} />
      <Login location={location} />
    </Layout>
  )
}

export default LoginPage
